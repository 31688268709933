<template>
  <div class="modal-add-task-participants" v-loading="loadingData">
    <div class="add-task-participants-body">
      <div class="title-task-participants">{{ $t('message.participants') }}</div>
        <div>
            <el-input
                class="w-100 mb-2"
                size="medium"
                placeholder="Поиск"
                prefix-icon="el-icon-search"
                @input="findUser"
                v-model="input2">
            </el-input>
        </div>
      <div class="itme-chek" v-for="user in sortedUsers" :key="user.id" @click="checkUser(user)">
        <el-checkbox v-model="user.checked">{{ user.name }}</el-checkbox>
      </div>
      <!-- end itme-chek -->
    </div>

    <div class="modal-footer-buts">
      <div class="d-flex justify-center">
        <el-button type="primary" size="medium" @click="submit(true)">{{ $t('message.save') }}</el-button>
        <el-button type="danger" plain size="medium"  @click="close()">{{ $t('message.cancel') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  mixins: [form, drawer],
  props: {
      participants:{
        default: Array
      },
      user_id:{
        default: null
      }
  },
  data() {
    return {
      input2: '',
      userList: [],
      sortedUsers: [],
      checkedUsers: [],
      loadingData: false,
    };
  },
  computed: {
      ...mapGetters({
          users: "users/inventory",
          getUserSearch: "users/search",
          authUser: "auth/user",
      }),
  },
  methods: {
      ...mapActions({
          updateInventory: "users/inventory",
          searchUser: "users/search",
      }),
      afterOpen(){
        this.loadingData = true;
        this.updateInventory()
          .then(res => {
            this.userList = []
            this.checkedUsers = []
            this.userList = JSON.parse(JSON.stringify(this.users));
            if(this.userList.length > 0){
              this.userList.forEach(user => {
                if(!this.participants.some(per => per.id == user.id) && this.user_id !== user.id ){
                  this.sortedUsers.push(user);
                }
              });
            }
            this.loadingData = false;
          });  
      },

      checkUser(user){
        setTimeout(() => {
          if(user.checked && !this.checkedUsers.find(item => item.id === user.id)){
            this.checkedUsers.push(user);
          }else if(!user.checked && this.checkedUsers.find(item => item.id === user.id)){
            this.checkedUsers = this.checkedUsers.filter(item => item.id !== user.id);
          }
        }, 100);
      },

      findUser(val){
          this.debouncedSearchUser(val, this)
      },

      debouncedSearchUser: _.debounce((search, self) => {
        if (_.isFunction(self.searchUser) && search.length > 0) {
          self.searchUser(search).then(res => {
              let list = JSON.parse(JSON.stringify(self.getUserSearch)).filter(item => (item.id !== self.authUser.id && item.id !== self.user_id));
              self.sortedUsers = JSON.parse(JSON.stringify(self.checkedUsers));
              list.forEach(element => {
                if(!self.checkedUsers.find(item => item.id === element.id) && !self.participants.find(item => item.id === element.id)){
                  self.sortedUsers.push(element);
                }
              });
          })
        }else{
          self.sortedUsers = [];
          if(self.userList.length > 0){
            self.userList.forEach(user => {
              if(!self.participants.some(per => per.id == user.id) && self.user_id !== user.id ){
                self.sortedUsers.push(user);
              }
            });
          }

          self.sortedUsers.forEach(element => {
            if(self.checkedUsers.map(item => item.id).includes(element.id)){
              element.checked = true;
            }
          });
        }
      }, 1000),

      submit(close){
        let users = this.sortedUsers.filter(per => per.checked);
        this.$emit("getCheckedUsers", users);
        if (close) this.close();
      },
      afterLeave(){
          this.userList = [],
          this.checkedUsers = [],
          this.sortedUsers = []
      }
  },

};
</script>

<style>
</style>
