<template>
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">{{
              $t("message.new_m", {
                m: $t("message.task"),
              })
            }}</p>
          <div>
            <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>

      <div class="app-modal__fulls p-5">
        <el-form ref="form" :model="form" :rules="rules" >

        <el-row :gutter="20">
          <el-col :span="18">
            <div class="wrap-all d-flex f-between f-wrap">
              <div class="w-100">
                <div class="gc-card rounded-sm mb-4 p-relative">
                  <div class="gc-card__header d-flex align-center px-3 py-4 font20">
                    <i class="el-icon-tickets mr-2"></i>
                    <div class="d-flex w-100">
                      <span class="font-bold">
                        {{
                          $t("message.new_m", {
                            m: $t("message.task"),
                          })
                        }}
                      </span>
                    </div>
                  </div>

                  <div class="gc-card__body px-3 py-4">
                    <el-form-item :label="$t('message.task_board_id')" prop="task_board_id" class="label_mini mb-0" v-if="board_id === null">
                      <select-board
                          :size="'medium'"
                          :placeholder="$t('message.task_board_id')"
                          :id="form.task_board_id"
                          v-model="form.task_board_id"
                      > 
                      </select-board>
                    </el-form-item>

                    <div class="task-input">
                      <el-form-item :label="$t('message.task')" prop="name" class="label_mini">
                            <crm-input :size="'medium'" :inputValue="form.name" v-model="form.name" :placeholder="$t('message.name')"></crm-input>
                      </el-form-item>
                    </div>
                    <!-- end task-input-textarea -->

                    <div class="user-metca mt-5">
                      <div class="gc-card-users__info mt-4 mr-3" v-if="form.participants && form.participants.length > 0">
                        <span class="font-bold mb-2 d-block"> {{ $t('message.participants') }} </span>

                        <div class="users__info">
                          <div class="task_user_give">
                            <div class="itme-userss">
                                <el-popover
                                    placement="top"
                                    popper-class="p-0 myir"
                                    width="300"
                                    trigger="click">
                                    <div class="click-block">
                                        <div class="top-u-click">
                                            <img class="task_user_give_img" :src="authUser.avatar ? authUser.avatar : '/img/flag/employee.svg'" alt="">
                                            <div class="tt-name">
                                                <div>{{ authUser.name }}</div>
                                                <span>{{ authUser.email ?  authUser.email : ''}}</span>
                                            </div>
                                        </div>
                                        <ul>
                                            <li>
                                                <span><i class="el-icon-warning-outline"></i>ID: {{ authUser.id }}</span>
                                            </li>
                                            <li>
                                                <span><i class="el-icon-mobile-phone"></i>Tel: {{ authUser.phone }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                    <el-button slot="reference">
                                        <div class="task_user_give_names"><img class="task_user_give_img" :src="authUser.avatar ? authUser.avatar : '/img/flag/employee.svg'" alt=""></div>
                                    </el-button>
                                </el-popover>
                            </div>
                          </div>
                          <i class="el-icon-arrow-right ml-1 mr-1"></i>
                          <div class="d-flex task_user_accept">
                            <div class="itme-userss">
                                <el-popover
                                    placement="top"
                                    popper-class="p-0 myir"
                                    width="300"
                                    v-for="participant in form.participants" :key="participant.id"
                                    trigger="click">
                                    <div class="click-block">
                                        <div class="top-u-click">
                                            <img class="task_user_give_img" :src="participant.avatar ? participant.avatar : '/img/flag/employee.svg'" alt="">
                                            <div class="tt-name">
                                                <div>{{ participant.name }}</div>
                                                <span>{{ participant.email ? participant.email : ''}}</span>
                                            </div>
                                        </div>
                                        <ul>
                                            <li>
                                                <span><i class="el-icon-warning-outline"></i>ID: {{ participant.id }}</span>
                                            </li>
                                            <li>
                                                <span><i class="el-icon-mobile-phone"></i>Tel: {{ participant.phone }}</span>
                                            </li>
                                        </ul>
                                        <div class="delet">
                                            <el-button size="small" class="w-100" type="danger" @click="deleteParticipant(participant.id)">{{ $t('message.delete_participant_from_list') }}</el-button>
                                        </div>
                                    </div>
                                    
                                    <el-button slot="reference">
                                        <img class="task_user_give_img" :src="participant.avatar ? participant.avatar : '/img/flag/employee.svg'" alt="">
                                    </el-button>
                                </el-popover>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="u-clearfix js-card-detail-labels-list mt-4" v-if="form.labels && form.labels.length > 0">
                        <div class="font-bold mb-2 d-block">{{ $t('message.labels') }}</div>

                        <span
                            class="card-label mod-card-detail"
                            title=""
                            :class="label.color ? label.color.name : 'color_1'"
                            v-for="label in form.labels" :key="label.id"
                            >
                          <span class="label-text"> {{ label.name }} <i class="hover-dis el-icon-circle-close" @click="removeLabelFromTask(label)"></i> </span>
                        </span>

                      </div>
                    </div>
                    <!-- end user-metca -->

                    <div class="data-task mt-5">
                      <div>
                        <div class="gc-card-users__info mt-4 mr-3" v-if="form.begin_date">
                          <span class="font-bold d-block"> {{ $t('message.deadline') }} </span>
                        </div>
                        <div class="my-button ml-2" style="justify-content: space-between; margin-left: 21px !important; width: 340px;" v-if="form.begin_date" @click="term = true">
                            <span>{{ form.begin_date }}</span>
                            <span><i class="el-icon-right"></i></span>
                            <span>{{ form.end_date }}</span>
                        </div>
                        <div class="data-task-cheek">
                          <div class="my-button ml-4" >
                            <el-date-picker
                                class="sroc"
                                v-model="form.finish_date"
                                type="date"
                                :disabled="!finishDateCheck"
                                placeholder="Pick a day"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd">>
                            </el-date-picker>
                            <span class="p-1" :class="finishDateCheck ? 'task_done' : (form.status === 4 ? 'task_for_check' : 'task_in_process_1')"> {{ finishDateCheck ? $t("message.completed") : (taskPercentage === 100 ? $t("message.check_task") : $t("message.in_process"))}}  </span>
                          </div>
                          <el-checkbox v-if="form.status === 4 && (authUser.is_super_admin || (authUser.id === (form.user ? form.user.id : 0)))" class="mt-2 ml-2 mr-0" size="mini" v-model="finishDateCheck" :label="$t('message.task_checked')" border></el-checkbox>
                          <el-checkbox v-if="miniTasksCount === 0" class="mt-2" size="mini" v-model="taskToChecking" :label="$t('message.ready_to_check')" border></el-checkbox>

                        </div>
                      </div>
                    </div>
                    <!-- end data-task -->

                    <div class="task-input-textarea mt-5">
                      <span class="font-bold mb-2 d-block">
                        <i class="el-icon-notebook-2"></i>  {{ $t("message.description") }}
                      </span>
                      <div class="text-comentariya">
                          <div class="text-big">
                                
                                <div class="big-text-contents" v-for="comment in form.comments" :key="comment.number">
                                  <div class="text-contents"> {{ comment.comment }}</div>
                                  <div class="update-i"><i class="el-icon-edit-outline"  @click="updateComment(comment)"></i></div>
                                  <div class="delent-i"><i class="el-icon-delete"  @click="destroyComment(comment)"></i></div>
                                </div>
                             
                          </div>

                      </div>

                      <div class="pl-2">
                        <el-input type="textarea" v-model="comment">
                        </el-input>
                      </div>
                      <button
                        class="my-button ml-2"
                        type="button"
                        style="width: auto"
                        @click="addCommentToForm()"
                      >
                        <span>{{ $t("message.add_description") }}</span>
                      </button>
                    </div>
                    <!-- end task-input-textarea -->

                    <div class="task-check-tasks d-block" v-for="list in form.checkLists" :key="list.number">
                      <div class="mt-5 mb-4 d-flex align-center">
                        <i class="el-icon-tickets mr-2"></i>
                        <div class="d-flex w-100">
                          <span class="font-bold"> {{ list.name }} </span>
                          <div
                            class="change-form ml-auto pointer"
                            ref="showChangeContentBtn"
                            @click="deleteCheckList(list)"
                          >
                            {{ $t("message.delete") }}
                          </div>
                        </div>
                      </div>

                      <div>
                        <el-progress
                          :text-inside="true"
                          :stroke-width="18"
                          :percentage="list.percentage"
                          status="success"
                        ></el-progress>
                      </div>


                      <div class="item-checkbox-task" v-for="miniTask in list.miniTasks" :key="miniTask.number">
                        <div class="checkbox-task">
                          <input type="checkbox" id="vehicle1" @change="checkListPercentage(miniTask.checkListNumber)" name="vehicle1" v-model="miniTask.is_done" value="Bike">
                          <!-- <el-checkbox v-model="miniTask.is_done" @change="checkTask(miniTask)"></el-checkbox> -->
                          <span class="checkbox-text ml-2">{{ miniTask.text }}</span>
                        </div>


                        <div class="checkbox-task-delet d-flex">
                          <i class="el-icon-delete" @click="deleteMiniTask(miniTask)"></i>  
                          <i class="el-icon-edit-outline" @click="updateMiniTask(miniTask)"></i>
                        </div>
                      </div>
                      <!-- end  item-checkbox-task-->

                      <button
                        class="my-button ml-2"
                        type="button"
                        style="width: auto"
                      >
                        <span @click="addMiniTask(list.number)">{{ $t("message.add_item") }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="wrap-all d-flex f-between f-wrap">
              <div class="w-100">
                <div class="gc-card rounded-sm mb-4 p-relative">
                  <div class="gc-card__header d-flex align-center px-3 py-4">
                    <i class="el-icon-setting mr-2"></i>
                    <div class="d-flex w-100">
                      <span class="font-bold">{{ $t("message.recommended_settings") }}</span>
                    </div>
                  </div>
                  <div class="gc-card__body px-3 py-4">
                    <div class="itme-right-Buttons mt-5">
                      <span class="text-descr__label d-block">
                        {{ $t("message.add_to_card") }}
                      </span>
                      <div
                        class="button-style-my mb-4"
                        @click="participants = true"
                      >
                        <i class="el-icon-user"></i>
                        <p>{{ $t("message.participants") }}</p>
                      </div>
                      <div class="button-style-my mb-4" @click="labels = true">
                        <i class="el-icon-discount"></i>
                        <p>{{ $t("message.labels") }}</p>
                      </div>
                      <div
                        class="button-style-my mb-4"
                        @click="addingTask = true"
                      >
                        <i class="el-icon-folder-checked"></i>
                        <p>{{ $t("message.check_list") }}</p>
                      </div>
                      <div class="button-style-my mb-4" @click="term = true">
                        <i class="el-icon-time"></i>
                        <p>{{ $t("message.deadline") }}</p>
                      </div>
                      <el-upload
                        v-model="form.files"
                        class="upload-demo wu-100 "
                        action="/"
                        :limit="10"
                        :accept="fileAccepts"
                        :on-exceed="handleExceed"
                        :on-change="createImageList"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :auto-upload="false"
                      >
                        <div class="button-style-my mb-4">
                          <i class="el-icon-paperclip"></i>
                          <p>{{ $t("message.attechment") }}</p> 
                        </div>

                        
                      </el-upload>      
                    </div>
                  </div>
                </div>

                <div class="gc-card rounded-sm mb-4 p-relative">
                   <div class="gc-card__header d-flex align-center px-3 py-4">
                    <i class="el-icon-s-order mr-2"></i>
                    <div class="d-flex w-100">
                      <span class="font-bold">{{$t('message.deal')}}</span>
                    </div>
                  </div>
                  <div class="p-3">
                    <el-form-item :label="$t('message.client')" class="label_mini mb-0">
                      <el-row>
                        <el-col :span="21">
                          <select-client
                            :size="'medium'"
                            :placeholder="$t('message.client')"
                            :id="client_id"
                            v-model="client_id"
                          >
                          </select-client>
                        </el-col>
                        <el-col :span="3">
                          <i @click="drawerCreateClient = true" class="el-icon-circle-plus-outline task-select-plus-icon"></i>
                        </el-col>
                      </el-row>
                    </el-form-item>

                    <el-form-item :label="$t('message.deal')" class="label_mini mb-0" prop="deal_id">
                      <el-row>
                        <el-col :span="21">
                          <select-deal
                            :size="'medium'"
                            :placeholder="columns.deal_id.title"
                            :id="form.deal_id"
                            v-model="form.deal_id"
                            :client_id="client_id"
                          >
                          </select-deal>
                        </el-col>
                        <el-col :span="3">
                          <i @click="drawerCreateDeal = true" class="el-icon-circle-plus-outline task-select-plus-icon"></i>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
            
      </el-form>

      </div>
    </div>

    <!-- the modals -->

    <el-dialog
      :title="$t('message.participants')"
      :visible.sync="participants"
      width="30%"
      :append-to-body="true"
      class="add-participants"
      ref="drawerParcipants"
      @opened="drawerOpened('drawerParcipantsChild')"
      @closed="drawerClosed('drawerParcipantsChild')"
    >
      <Participants
        ref="drawerParcipantsChild"
        drawer="drawerParcipants"
        :participants="form.participants"
        :user_id="authUser ? authUser.id : null"
        @getCheckedUsers="updateParticipants"
        > </Participants>
    </el-dialog>
    <!-- end Participants -->

    <el-dialog
      :title="$t('message.adding_a_task_list')"
      :visible.sync="addingTask"
      width="30%"
      :append-to-body="true"
      class="add-participants"
      ref="drawerCheckLists"
      @opened="drawerOpened('drawerCheckListsChild')"
      @closed="drawerClosed('drawerCheckListsChild')"
    >
      <AddingTask
        ref="drawerCheckListsChild"
        drawer="drawerCheckLists"
        @getNewCheckList="updateCheckList"
        > </AddingTask>
    </el-dialog>
    <!-- end AddingTask -->

     <el-dialog
      :title="$t('message.add_task')"
      :visible.sync="addingMiniTask"
      width="30%"
      :append-to-body="true"
      class="add-participants"
      ref="drawerCreateMiniTasks"
      @opened="drawerOpened('drawerCreateMiniTasksChild')"
      @closed="drawerClosed('drawerCreateMiniTasksChild')"
    >
      <CreateMiniTask
        :checkListNumber="checkListForMiniTask"
        ref="drawerCreateMiniTasksChild"
        drawer="drawerCreateMiniTasks"
        @getNewMiniTask="updateMiniTaskList"
        > </CreateMiniTask>
    </el-dialog>

    <el-dialog
      :title="$t('message.new_m', {m: $t('message.task')})"
      :visible.sync="updateMiniTaskDrawer"
      width="30%"
      :append-to-body="true"
      class="add-participants"
      ref="drawerUpdateMiniTasks"
      @opened="drawerOpened('drawerUpdateMiniTasksChild')"
      @closed="drawerClosed('drawerUpdateMiniTasksChild')"
    >
      <UpdateMiniTask
        :model="selectedMiniTaskForUpdate"
        ref="drawerUpdateMiniTasksChild"
        drawer="drawerUpdateMiniTasks"
        @updateMiniTask="updateMiniTaskList"
        > </UpdateMiniTask>
    </el-dialog>


    <el-dialog
      :title="$t('message.dates')"
      :visible.sync="term"
      width="40%"
      :append-to-body="true"
      class="add-participants"
      ref="drawerDates"
      @opened="drawerOpened('drawerDatesChild')"
      @closed="drawerClosed('drawerDatesChild')"
    >
      <Term
        ref="drawerDatesChild"
        drawer="drawerDates"
        @getDates="updateDates"
        :taskDates="taskDates"
        > </Term>
    </el-dialog>
    <!-- end AddingTask -->

    <el-dialog
      :title="$t('message.labels')"
      :visible.sync="labels"
      width="35%"
      :append-to-body="true"
      class="add-participants"
      ref="drawerLabels"
      @opened="drawerOpened('drawerLabelsChild')"
      @closed="drawerClosed('drawerLabelsChild')"
    >
      <Tags
        ref="drawerLabelsChild"
        drawer="drawerLabels"
        :selectedTaskLabels="form.labels"
        @getTaskLabels="updateTaskLabels"
        > </Tags>
    </el-dialog>
    <!-- end metka -->

    <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreateClient"
          ref="drawerCreateClient"
          size="70%"
          :append-to-body="true"
          class="body_scroll_70" 
          @opened="drawerOpened('drawerCreateClientChild')"
          @closed="drawerClosed('drawerCreateClientChild')"
        >
            <div>
                <create-client
                    ref="drawerCreateClientChild"
                    drawer="drawerCreateClient"
                >
                </create-client>
            </div>
        </el-drawer>
    
    
        <el-drawer
          size="80%"
          :append-to-body="true"
          class="body_scroll_80 mt-00" 
          :visible.sync="drawerCreateDeal"
          :fullscreen="true"
          ref="drawerDealCreate"
          custom-class="ge"
          @opened="drawerOpened('drawerDealCreateChild')"
          @closed="drawerClosed('drawerDealCreateChild')"
          >
          <div
              @click="closeAddModel()"
              class="
              close-modal-stick
              d-flex
              align-center
              p-fixed
              text-white
              p-2
              font-bold
              pointer
              transition
              "
          >
              <div class="close-modal-icon text-center rounded-circle transition">
              <i class="el-icon-close"></i>
              </div>
              <span class="close-modal-text text-uppercase ml-2"></span>
          </div>
          <DealCreateForTask
              ref="drawerDealCreateChild"
              :dealCreate="dealCreate"
              drawer="drawerDealCreate"
          />
        </el-drawer>
    
    </div>
  </div>
</template>        
<script>
import Participants from "./participants";
import AddingTask from "./addingTask";
import CreateMiniTask from "./addingMiniTaskForCheckList";
import UpdateMiniTask from "./updateMiniTaskForCheckList";
import Term from "./term";
import Tags from "./tags";
import selectDeal from "@/components/inventory/select-deal";
import selectClient from "@/components/selects/select-client";
import selectBoard from "@/components/inventory/select-task-board";
import CreateClient from "@/views/clients/components/crm-create";
import DealCreateForTask from "@/views/deals/components/add-modal";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import task from "@/utils/mixins/views/task";


export default {
  name: 'task_modal',
  mixins: [form, drawer, task],
  components: {
    Participants,
    AddingTask,
    Term,
    Tags,
    CreateMiniTask,
    UpdateMiniTask,
    selectDeal,
    selectClient,
    selectBoard,
    CreateClient,
    DealCreateForTask,
  },
  props: {
    board_id:{
      default: null
    },
    selected_deal:{
      default: Object
    }
  },
  data() {
    return {
      taskToChecking: false,
      drawerCreateClient: false,
      drawerCreateDeal: false,
      dealCreate: true,
      comment: "",
      client_id: null,
      participants: false,
      labels: false,
      term: false,
      addingMiniTask: false,
      updateMiniTaskDrawer: false,
      checkListForMiniTask: null,
      selectedMiniTaskForUpdate: {},
      deal_id: null,
      finishDateCheck: false,
      inputTask: "",
      value: "",
      value1: "",
      image: "",
      addingTask: false,
      fileList: [],
      updateFile: [],
      taskDates: [],
      taskPercentage: 0,
      miniTasksCount: 0,
      fileAccepts: "image/png, image/jpeg, application/pdf, .doc,.docx,application/msword,.xls,.xlsx,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain,",
    };
  },
  computed: {
      ...mapGetters({
          authUser: "auth/user",
          rules: "tasks/rules",
          model: "tasks/model",
          columns: "tasks/columns",
      }),
  },
  watch: {
      finishDateCheck(val) {
        let newDate = new Date();

        if(val){
          this.form.finish_date = this.formatDate(newDate);
        }else{
          this.form.finish_date = ''
        }
      },
      form: {
          handler: async function(newVal, oldVal) {
              this.taskPercentage = 0;
              let completed = 0;
              let uncompleted = 0;
              this.form.checkLists.forEach(check_list => {
                check_list.miniTasks.forEach(mini_task => {
                  if(mini_task.is_done){
                    completed += 1
                  }else{
                    uncompleted += 1
                  }
                });
              });
              this.miniTasksCount = completed + uncompleted;
              if((completed + uncompleted) === 0){
                this.taskPercentage = 0;
              }else{
                this.taskPercentage = 100*completed/(completed + uncompleted);
              }
              if(this.taskPercentage === 100 && this.miniTasksCount > 0){
                this.form.status = 4;
              }
              if(this.taskPercentage < 100 && this.miniTasksCount > 0){
                this.form.status = 1;
              }
              if(this.form.status === 4){
                this.taskToChecking = true;
              }
          },
          deep: true,
          immediate: true
      },
      taskToChecking: {
          handler: async function(newVal, oldVal) {
             if(newVal){
               this.form.status = 4;
             }else{
               this.form.status = 1;
             }
          },
          deep: true,
          immediate: true
      },

  },
  methods: {
    ...mapActions({
        save: "tasks/store",
        saveFiles: "tasks/storeFiles",
        empty: "tasks/empty",
        updateDealTasks: "deals/dealTasks",
        updateDealsList: "boards/index",
    }),
    afterOpen(){
      if(this.selected_deal){
        this.form.deal_id = this.selected_deal.id;
        this.client_id = this.selected_deal.client ? this.selected_deal.client.id : null;
      }
    }
    
    
  },
};
</script>
<style>
  .task-select-plus-icon {
    font-size: 34px; 
    margin: 2px 10px 
  }
  .task_in_process_1 {
    background-color: rgb(255, 102, 0);
    color: white;
  }
  .task_for_check {
    background-color: rgb(50, 127, 226);
    color: white;

  }
  .task_done {
    background-color: rgb(92, 196, 92);
    color: white;
  }
  .wu-100 .el-upload{
    width: 100%;
  }
  .text-contents{
    min-height: 33px;
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 100%;
    font-size: inherit;
    color: #606266;
    background-color: #FFF;
    background-image: none;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  }
  .big-text-contents{
    display: flex;
    align-items: center;
    margin: 0 0 20px 6px;
  }
    .big-text-contents div i{
      font-size: 24px;
      padding: 5px;
    }
    .update-i {
      color: #2775a8;
    }
    .update-i {
      color: #2775a8;
    }
</style>
